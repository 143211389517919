import React from 'react'

import FormSuccessTemplate from '../../../components/templates/FormSuccessTemplate'

const Success = () => {
  return (
    <FormSuccessTemplate
      title="Succès - Créer une alerte"
      messageTitle='Votre alerte est bien enregistrée'
      messageSubContent='Vous allez recevoir un email de confirmation. Pensez à regarder vos spams.'
      messageContent="Vous serez informés et contactés dès qu'une opportunité correspondra à vos critères."
      description="Votre création d'alerte a bien été enregistrée sur notre plateforme TrouverMonLocalPro !" />
  )
}

export default Success
